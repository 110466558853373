<template>
  <div id="videoSearchSet">
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="14">
        <el-button @click="addVideoResConfig">增加资源</el-button>
        <el-button @click="insVideoFieldConfig">保存</el-button>
        <!--        <el-button @click="delVideoResConfig">保存全部资源</el-button>-->
        <el-table
            :data="tableData"
            border
            highlight-current-row
            @current-change="examine"
            style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="播放地址"  width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.link"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-input v-model="scope.row.img"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="播放器类型" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.desc" placeholder="请选择">
                <el-option
                    v-for="item in playerType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="影视类型" width="150">
            <template slot-scope="scope">
              <el-input v-model="scope.row.type"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="delVideoResConfig(scope.$index, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="10">
        <el-button @click="addVideoFieldConfig">新增剧集</el-button>
        <el-table
            :data="videoResCfg.list"
            border
            style="width: 100%">
          <el-table-column label="名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.title"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-input v-model="scope.row.img"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="播放地址">
            <template slot-scope="scope">
              <el-input v-model="scope.row.link"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="delVideoFieldConfig(scope.$index, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ExpandIndex from "../../request/expand/index";

export default {
  name: "insideTvAdd",
  data() {
    return {
      tvAsk: new ExpandIndex(),
      radio: '1',
      tableData: [],
      videoResCfg: {
        title: "",
        img: "",
        desc: "cPlayer",
        type: "",
        list: []
      },
      playerType: [
        {
          value: 'analysis',
          label: '视频解析'
        },
        {
          value: 'cPlayer',
          label: '外置播放器'
        },
        {
          value: 'inside',
          label: '内置播放器'
        },
        {
          value: 'cronTv',
          label: '资源内指定播放器'
        }
      ]
    }
  }
  ,
  created() {
  },
  mounted() {
  },
  methods: {
    /**
     * 删除字段
     */
    delVideoFieldConfig(index, row) {
      this.videoResCfg.list.splice(index, 1);
    },
    /**
     * 新增字段
     */
    addVideoFieldConfig() {
      this.videoResCfg.list.push(
          {
            "title": "",
            "img": "",
            "link": ""
          }
      )
    },
    insVideoFieldConfig(index, row) {
      this.$confirm('请确认操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log(this.tableData)
        this.tvAsk.insideTvAdd(this.tableData).then(res => {
          this.$message({
            type: 'success',
            showClose: true,
            message: '新增成功!'
          });
        }).catch(e => {
          this.$message.error('操作异常');
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          showClose: true,
          message: '已取消删除'
        });
      })
    },
    /**
     * 删除资源
     */
    delVideoResConfig(index, item) {
      this.$confirm('请确认操作, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableData.splice(index, 1);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    /**
     * 新增资源
     */
    addVideoResConfig() {
      this.tableData.push(
          {
            "title": "",
            "img": "",
            "desc": "cPlayer",
            "type": "",
            "list": []
          }
      )
    },
    /**
     * 选中配置字段显示
     * @param row
     */
    examine(row) {
      console.log(row)
      this.videoResCfg = row
    }
  }
}
</script>

<style scoped>
#videoSearchSet {
  text-align: left;
}

ul {
  padding: 0;
  text-align: left;
  height: 400px;
  overflow: auto
}

li {
  list-style: none;
  padding: 5px 0;
  border-bottom: 1px solid;
}
</style>